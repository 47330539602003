import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React from "react";

import logo from "../../../assets/img/avatars/modal-logo.png";
var imgStyle = {
  width: "150px",
};
const DeleteModal = ({ show, toggle, onSubmit, user }) => {
  return (
    <Modal isOpen={show} toggle={toggle} centered={true} fade={false}>
      <ModalHeader toggle={toggle}>
        {/* <img src={logo}  className="modallogo" /> */}
      </ModalHeader>
      <div>
        <span className="modaltitle modaltitleuser p-0">Delete User</span>
      </div>
      <ModalBody>
        <strong>{user.Email}</strong> is about to be deleted. Are you sure you
        want to proceed?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSubmit}>
          Yes
        </Button>
        <Button color="secondary" onClick={toggle}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
